import * as Sentry from '@sentry/core';

/**
 * Error handler for all Vue errors. That includes:
 * Component renders
 * Event handlers
 * Lifecycle hooks
 * setup() function
 * Watchers
 * Custom directive hooks
 * Transition hooks
 *
 * This does not capture regular data fetching
 */
export default defineNuxtPlugin({
	name: 'frontend-error-handler-plugin',
	parallel: true,
	setup(nuxtApp) {
		nuxtApp.vueApp.config.errorHandler = (error) => {
			Sentry.captureException(error);
		};
	},
});

<template>
	<div class="flex h-screen w-full flex-col items-center justify-center gap-4 text-center">
		<h1>Uh oh, looks like we're lost...</h1>
		<p v-if="isDevEnv">{{ error }}</p>
		<ButtonSolid primary-color @click="handleError">The weed is this way</ButtonSolid>
	</div>
</template>

<script setup lang="ts">
import { onUnmounted } from 'vue';
import ButtonSolid from './components/ui/ButtonSolid.vue';
import type { NuxtError } from 'nuxt/app';

defineProps<{ error: NuxtError }>();

const handleError = () => clearError({ redirect: '/#shop' });
const isDevEnv = process.env.NODE_ENV === 'development';

onUnmounted(() => clearError());
</script>

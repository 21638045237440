import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
	name: 'sentry-client-plugin',
	parallel: true,
	setup(nuxtApp) {
		const runtimeConfig = useRuntimeConfig();

		const validationErrors = [
			'Email is required.',
			'First name is required.',
			'Invalid birthday format.',
			'Invalid email format.',
			'Invalid file size format.',
			'Invalid location.',
			'Invalid parking spot number.',
			'Invalid phone number.',
			'Last name is required.',
			'Math problem is incorrect.',
			'Must provide a valid preorder ID.',
			'Preorder ID does not exist.',
			'The resource already exists',
			'Product not found',
			`NotAllowedError: play() failed because the user didn't interact with the document first.`,
		];

		if (process.env.NODE_ENV !== 'development') {
			Sentry.init({
				app: nuxtApp.vueApp,
				debug: process.env.NODE_ENV !== 'production',
				dsn: runtimeConfig.public.SENTRY_DSN,
				environment: process.env.NUXT_ENV_IS_STAGING === 'true' ? 'staging' : 'production',
				release: runtimeConfig.SENTRY_RELEASE,
				// Performance Monitoring
				tracesSampleRate: 0.2,

				beforeSend(event, hint) {
					const error = hint.originalException;

					if (error === 'Timeout') return null; // recaptcha bug
					if (
						error &&
						error instanceof Error &&
						error.message &&
						validationErrors.includes(error.message)
					) {
						return null;
					}
					return event;
				},
			});
		}
	},
});

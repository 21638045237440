// This plugin fixes an issue that occurs when a user has the site open and a new deploy occurs
// When this happens the build hash changes and the site on the users client is trying to pull to
// old one so it results in a 404
export default defineNuxtPlugin({
	name: 'hard-refresh-plugin',
	parallel: true,
	setup(nuxtApp) {
		const router = nuxtApp.vueApp.config.globalProperties.$router;
		router.onError((error, to) => {
			if (
				error.message.includes('Failed to fetch dynamically imported module') ||
				error.message.includes('Importing a module script failed')
			) {
				window.location.href = to.fullPath;
			}
		});
	},
});

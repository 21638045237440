export default defineNuxtPlugin({
	name: 'scroll-position-plugin',
	parallel: true,
	setup(nuxtApp) {
		nuxtApp.hook('page:finish', () => {
			if (nuxtApp._route.path.startsWith('/dashboard')) {
				const dashboardBody = document.getElementById('dashboard-body');
				if (dashboardBody) {
					dashboardBody.scrollTo(0, 0);
				}
			}
		});
	},
});

export default defineNuxtPlugin({
	name: 'recaptcha-plugin',
	parallel: true,
	setup() {
		const v3Key = useRuntimeConfig().public.recaptchaSiteKeyV3;

		function loadReCaptchaScript() {
			if (document.getElementById('recaptcha-script')) return;
			const script = document.createElement('script');
			script.id = 'recaptcha-script';
			script.src = `https://www.google.com/recaptcha/api.js?render=${v3Key}`;
			script.async = true;
			script.defer = true;
			document.head.appendChild(script);

			['click', 'touchstart', 'keydown', 'scroll', 'mousemove'].forEach((event) =>
				window.removeEventListener(event, loadReCaptchaScript),
			);
		}

		['click', 'touchstart', 'keydown', 'scroll', 'mousemove'].forEach((event) =>
			window.addEventListener(event, loadReCaptchaScript, { once: true }),
		);
	},
});

import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
	name: 'gtm-plugin',
	parallel: true,
	setup(nuxtApp) {
		const isStaging = useRuntimeConfig().public.isStaging;
		const isProd = !import.meta.dev && !isStaging;

		function initializeGTM() {
			try {
				nuxtApp.vueApp.use(
					createGtm({
						id: isProd ? 'GTM-P696V3T' : 'GTM-P3QJ9CX2',
						debug: import.meta.dev ? true : false,
						defer: true,
						compatibility: true,
						loadScript: true,
						vueRouter: useRouter(),
						ignoredViews: (to) => {
							return to.path.startsWith('/dashboard');
						},
						trackOnNextTick: true,
					}),
				);
			} catch (error) {
				if (isProd) {
					Sentry.captureException(error, {
						tags: { module: 'GTM' },
						extra: { event: 'Init' },
					});
				} else {
					console.error('Failed to initialize GTM:', error);
				}
			}
		}

		let gtmInitialized = false;

		function initGTMOnInteraction() {
			if (!gtmInitialized) {
				try {
					initializeGTM();
					gtmInitialized = true;

					['click', 'touchstart', 'keydown', 'scroll', 'mousemove'].forEach(
						(eventType) => {
							document.removeEventListener(eventType, initGTMOnInteraction);
						},
					);
				} catch (error) {
					if (isProd) {
						Sentry.captureException(error, {
							tags: { module: 'GTM' },
							extra: { event: 'userInteraction' },
						});
					} else {
						console.error('Error during GTM initialization on interaction:', error);
					}
				}
			}
		}

		if (import.meta.client) {
			try {
				['click', 'touchstart', 'keydown', 'scroll', 'mousemove'].forEach((eventType) => {
					document.addEventListener(eventType, initGTMOnInteraction, { once: true });
				});
			} catch (error) {
				if (isProd) {
					Sentry.captureException(error, {
						tags: { module: 'GTM' },
						extra: { event: 'addEventListeners' },
					});
				} else {
					console.error('Error adding GTM event listeners:', error);
				}
			}
		}
	},
});

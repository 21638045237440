import DOMPurify from 'dompurify';

export default defineNuxtPlugin({
	name: 'sanitize-plugin',
	parallel: true,
	async setup() {
		let sanitizeHtml: (dirty: string) => string;

		if (import.meta.server) {
			const { JSDOM } = await import('jsdom');
			sanitizeHtml = DOMPurify(new JSDOM('').window).sanitize;
		} else {
			sanitizeHtml = DOMPurify.sanitize;
		}

		return {
			provide: {
				sanitizeHtml,
			},
		};
	},
});

import { default as _36100_45ounces50rpFUCQbyMeta } from "/tmp/build_2c622580/pages/$100-ounces.vue?macro=true";
import { default as accessibility6bbUwy4xZPMeta } from "/tmp/build_2c622580/pages/accessibility.vue?macro=true";
import { default as _91slug_93ak2JX7e6BVMeta } from "/tmp/build_2c622580/pages/brands/[slug].vue?macro=true";
import { default as build_45a_45packxwgKRjwMZQMeta } from "/tmp/build_2c622580/pages/build-a-pack.vue?macro=true";
import { default as cannabis_45pricingOxec5nxFjxMeta } from "/tmp/build_2c622580/pages/cannabis-pricing.vue?macro=true";
import { default as checkoutuXv5FnN9yhMeta } from "/tmp/build_2c622580/pages/checkout.vue?macro=true";
import { default as contactAL84lzTfQ7Meta } from "/tmp/build_2c622580/pages/contact.vue?macro=true";
import { default as confirmafRQVaMklbMeta } from "/tmp/build_2c622580/pages/dashboard/confirm.vue?macro=true";
import { default as indexJpjatehMkBMeta } from "/tmp/build_2c622580/pages/dashboard/index.vue?macro=true";
import { default as _91id_93L4FkEpjDJHMeta } from "/tmp/build_2c622580/pages/dashboard/jobs/[id].vue?macro=true";
import { default as createW6SJxTxKl2Meta } from "/tmp/build_2c622580/pages/dashboard/jobs/create.vue?macro=true";
import { default as indexKBkE5uCPjZMeta } from "/tmp/build_2c622580/pages/dashboard/jobs/index.vue?macro=true";
import { default as login5HR0PRIqW6Meta } from "/tmp/build_2c622580/pages/dashboard/login.vue?macro=true";
import { default as bundle_45image_45builderAxkckVY0kRMeta } from "/tmp/build_2c622580/pages/dashboard/media/bundle-image-builder.vue?macro=true";
import { default as index73xX5KHmRbMeta } from "/tmp/build_2c622580/pages/dashboard/media/index.vue?macro=true";
import { default as indexZadX4GZStvMeta } from "/tmp/build_2c622580/pages/dashboard/menu/index.vue?macro=true";
import { default as _91_46_46_46slug_93fgfiwYsUNpMeta } from "/tmp/build_2c622580/pages/dashboard/pages/[...slug].vue?macro=true";
import { default as homevtAVOHTk3PMeta } from "/tmp/build_2c622580/pages/dashboard/pages/home.vue?macro=true";
import { default as index4ofU6LXMMhMeta } from "/tmp/build_2c622580/pages/dashboard/pages/index.vue?macro=true";
import { default as _91id_933oacGX2l35Meta } from "/tmp/build_2c622580/pages/dashboard/products/[id].vue?macro=true";
import { default as indextRCtMKc2sWMeta } from "/tmp/build_2c622580/pages/dashboard/products/index.vue?macro=true";
import { default as forecastsJdMM1fIHzGMeta } from "/tmp/build_2c622580/pages/dashboard/reporting/forecasts.vue?macro=true";
import { default as _91id_930FlrOjKVEXMeta } from "/tmp/build_2c622580/pages/dashboard/reviews/[id].vue?macro=true";
import { default as indexhJ7rKV5ByOMeta } from "/tmp/build_2c622580/pages/dashboard/reviews/index.vue?macro=true";
import { default as settingsv9D90S31fHMeta } from "/tmp/build_2c622580/pages/dashboard/settings.vue?macro=true";
import { default as themeHYRHYZCf3VMeta } from "/tmp/build_2c622580/pages/dashboard/theme.vue?macro=true";
import { default as _91id_93a2YEqRQTshMeta } from "/tmp/build_2c622580/pages/dashboard/users/[id].vue?macro=true";
import { default as indexIpIcKCnbfoMeta } from "/tmp/build_2c622580/pages/dashboard/users/index.vue?macro=true";
import { default as index3gbwTPqG7jMeta } from "/tmp/build_2c622580/pages/dashboard/web-vitals/index.vue?macro=true";
import { default as dashboardYqXtsKO9WWMeta } from "/tmp/build_2c622580/pages/dashboard.vue?macro=true";
import { default as dispensary_45birthday_45deals_45michiganJYHaQjNpalMeta } from "/tmp/build_2c622580/pages/dispensary-birthday-deals-michigan.vue?macro=true";
import { default as dispensary_45dealswdrEpfPaEnMeta } from "/tmp/build_2c622580/pages/dispensary-deals.vue?macro=true";
import { default as first_45time_45dispensary_45deals9vGRC1qgzaMeta } from "/tmp/build_2c622580/pages/first-time-dispensary-deals.vue?macro=true";
import { default as glass_45jarsXu4CVLpx1vMeta } from "/tmp/build_2c622580/pages/glass-jars.vue?macro=true";
import { default as grow_45facility_45tour0NLXE04ozUMeta } from "/tmp/build_2c622580/pages/grow-facility-tour.vue?macro=true";
import { default as halloweenqj2WUPbOJ2Meta } from "/tmp/build_2c622580/pages/halloween.vue?macro=true";
import { default as indexWSHyW6JIvBMeta } from "/tmp/build_2c622580/pages/index.vue?macro=true";
import { default as _91slug_9388LSUZmTbdMeta } from "/tmp/build_2c622580/pages/jobs/[slug].vue?macro=true";
import { default as indexn7sFDIcjZiMeta } from "/tmp/build_2c622580/pages/jobs/index.vue?macro=true";
import { default as cannabinoidsIvm3o1hsQdMeta } from "/tmp/build_2c622580/pages/learn/cannabinoids.vue?macro=true";
import { default as cannabis_45anatomykvcyCFJb78Meta } from "/tmp/build_2c622580/pages/learn/cannabis-anatomy.vue?macro=true";
import { default as cannabis_45historyn9CQltKp9fMeta } from "/tmp/build_2c622580/pages/learn/cannabis-history.vue?macro=true";
import { default as michigan_45dispensary_45purchase_45limitsv0YVLfrxJHMeta } from "/tmp/build_2c622580/pages/learn/michigan-dispensary-purchase-limits.vue?macro=true";
import { default as terpenes_45over_45potencyWHoTmZlflYMeta } from "/tmp/build_2c622580/pages/learn/terpenes-over-potency.vue?macro=true";
import { default as what_45is_45cannabis8LfbDXSVOUMeta } from "/tmp/build_2c622580/pages/learn/what-is-cannabis.vue?macro=true";
import { default as media_45accessZ3oLyiSOoBMeta } from "/tmp/build_2c622580/pages/media-access.vue?macro=true";
import { default as michigan_45dispensary_45near_45indianaJRxe9rKXJ9Meta } from "/tmp/build_2c622580/pages/michigan-dispensary-near-indiana.vue?macro=true";
import { default as michigan_45dispensary_45near_45ohioRJo99alA6UMeta } from "/tmp/build_2c622580/pages/michigan-dispensary-near-ohio.vue?macro=true";
import { default as newsletterB3LVv6lmVtMeta } from "/tmp/build_2c622580/pages/newsletter.vue?macro=true";
import { default as order_45trackerFD4T28IZK3Meta } from "/tmp/build_2c622580/pages/order-tracker.vue?macro=true";
import { default as privacy_45policyE7T212anPXMeta } from "/tmp/build_2c622580/pages/privacy-policy.vue?macro=true";
import { default as _91slug_935hjaY8WngVMeta } from "/tmp/build_2c622580/pages/products/[slug].vue?macro=true";
import { default as quick_45ordersbU46n8rLyMeta } from "/tmp/build_2c622580/pages/quick-order.vue?macro=true";
import { default as return_45policyxyRugtI1TGMeta } from "/tmp/build_2c622580/pages/return-policy.vue?macro=true";
import { default as reviewsYvZcUib36LMeta } from "/tmp/build_2c622580/pages/reviews.vue?macro=true";
import { default as staging_45loginS0RmCDkbJTMeta } from "/tmp/build_2c622580/pages/staging-login.vue?macro=true";
import { default as strain_45libraryRJ7J9drB0qMeta } from "/tmp/build_2c622580/pages/strain-library.vue?macro=true";
import { default as terms_45of_45useUFfCP0m9bKMeta } from "/tmp/build_2c622580/pages/terms-of-use.vue?macro=true";
import { default as thank_45you7WUlXzhVf9Meta } from "/tmp/build_2c622580/pages/thank-you.vue?macro=true";
import { default as weedys_45weargIiN7nWsRkMeta } from "/tmp/build_2c622580/pages/weedys-wear.vue?macro=true";
import { default as wholesaleGptD26WKNKMeta } from "/tmp/build_2c622580/pages/wholesale.vue?macro=true";
export default [
  {
    name: "$100-ounces",
    path: "/$100-ounces",
    component: () => import("/tmp/build_2c622580/pages/$100-ounces.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/tmp/build_2c622580/pages/accessibility.vue")
  },
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/tmp/build_2c622580/pages/brands/[slug].vue")
  },
  {
    name: "build-a-pack",
    path: "/build-a-pack",
    component: () => import("/tmp/build_2c622580/pages/build-a-pack.vue")
  },
  {
    name: "cannabis-pricing",
    path: "/cannabis-pricing",
    component: () => import("/tmp/build_2c622580/pages/cannabis-pricing.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_2c622580/pages/checkout.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_2c622580/pages/contact.vue")
  },
  {
    name: dashboardYqXtsKO9WWMeta?.name,
    path: "/dashboard",
    meta: dashboardYqXtsKO9WWMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-confirm",
    path: "confirm",
    meta: confirmafRQVaMklbMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/confirm.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/tmp/build_2c622580/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-jobs-id",
    path: "jobs/:id()",
    component: () => import("/tmp/build_2c622580/pages/dashboard/jobs/[id].vue")
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    component: () => import("/tmp/build_2c622580/pages/dashboard/jobs/create.vue")
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/tmp/build_2c622580/pages/dashboard/jobs/index.vue")
  },
  {
    name: "dashboard-login",
    path: "login",
    meta: login5HR0PRIqW6Meta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/login.vue")
  },
  {
    name: "dashboard-media-bundle-image-builder",
    path: "media/bundle-image-builder",
    meta: bundle_45image_45builderAxkckVY0kRMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/media/bundle-image-builder.vue")
  },
  {
    name: "dashboard-media",
    path: "media",
    meta: index73xX5KHmRbMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/media/index.vue")
  },
  {
    name: "dashboard-menu",
    path: "menu",
    component: () => import("/tmp/build_2c622580/pages/dashboard/menu/index.vue")
  },
  {
    name: "dashboard-pages-slug",
    path: "pages/:slug(.*)*",
    meta: _91_46_46_46slug_93fgfiwYsUNpMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/pages/[...slug].vue")
  },
  {
    name: "dashboard-pages-home",
    path: "pages/home",
    meta: homevtAVOHTk3PMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/pages/home.vue")
  },
  {
    name: "dashboard-pages",
    path: "pages",
    meta: index4ofU6LXMMhMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/pages/index.vue")
  },
  {
    name: "dashboard-products-id",
    path: "products/:id()",
    meta: _91id_933oacGX2l35Meta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/products/[id].vue")
  },
  {
    name: "dashboard-products",
    path: "products",
    meta: indextRCtMKc2sWMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/products/index.vue")
  },
  {
    name: "dashboard-reporting-forecasts",
    path: "reporting/forecasts",
    component: () => import("/tmp/build_2c622580/pages/dashboard/reporting/forecasts.vue")
  },
  {
    name: "dashboard-reviews-id",
    path: "reviews/:id()",
    meta: _91id_930FlrOjKVEXMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/reviews/[id].vue")
  },
  {
    name: "dashboard-reviews",
    path: "reviews",
    meta: indexhJ7rKV5ByOMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/reviews/index.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: settingsv9D90S31fHMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-theme",
    path: "theme",
    meta: themeHYRHYZCf3VMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/theme.vue")
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    meta: _91id_93a2YEqRQTshMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/users/[id].vue")
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: indexIpIcKCnbfoMeta || {},
    component: () => import("/tmp/build_2c622580/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-web-vitals",
    path: "web-vitals",
    component: () => import("/tmp/build_2c622580/pages/dashboard/web-vitals/index.vue")
  }
]
  },
  {
    name: "dispensary-birthday-deals-michigan",
    path: "/dispensary-birthday-deals-michigan",
    component: () => import("/tmp/build_2c622580/pages/dispensary-birthday-deals-michigan.vue")
  },
  {
    name: "dispensary-deals",
    path: "/dispensary-deals",
    component: () => import("/tmp/build_2c622580/pages/dispensary-deals.vue")
  },
  {
    name: "first-time-dispensary-deals",
    path: "/first-time-dispensary-deals",
    component: () => import("/tmp/build_2c622580/pages/first-time-dispensary-deals.vue")
  },
  {
    name: "glass-jars",
    path: "/glass-jars",
    component: () => import("/tmp/build_2c622580/pages/glass-jars.vue")
  },
  {
    name: "grow-facility-tour",
    path: "/grow-facility-tour",
    component: () => import("/tmp/build_2c622580/pages/grow-facility-tour.vue")
  },
  {
    name: "halloween",
    path: "/halloween",
    component: () => import("/tmp/build_2c622580/pages/halloween.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_2c622580/pages/index.vue")
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/tmp/build_2c622580/pages/jobs/[slug].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/tmp/build_2c622580/pages/jobs/index.vue")
  },
  {
    name: "learn-cannabinoids",
    path: "/learn/cannabinoids",
    component: () => import("/tmp/build_2c622580/pages/learn/cannabinoids.vue")
  },
  {
    name: "learn-cannabis-anatomy",
    path: "/learn/cannabis-anatomy",
    component: () => import("/tmp/build_2c622580/pages/learn/cannabis-anatomy.vue")
  },
  {
    name: "learn-cannabis-history",
    path: "/learn/cannabis-history",
    component: () => import("/tmp/build_2c622580/pages/learn/cannabis-history.vue")
  },
  {
    name: "learn-michigan-dispensary-purchase-limits",
    path: "/learn/michigan-dispensary-purchase-limits",
    component: () => import("/tmp/build_2c622580/pages/learn/michigan-dispensary-purchase-limits.vue")
  },
  {
    name: "learn-terpenes-over-potency",
    path: "/learn/terpenes-over-potency",
    component: () => import("/tmp/build_2c622580/pages/learn/terpenes-over-potency.vue")
  },
  {
    name: "learn-what-is-cannabis",
    path: "/learn/what-is-cannabis",
    component: () => import("/tmp/build_2c622580/pages/learn/what-is-cannabis.vue")
  },
  {
    name: "media-access",
    path: "/media-access",
    component: () => import("/tmp/build_2c622580/pages/media-access.vue")
  },
  {
    name: "michigan-dispensary-near-indiana",
    path: "/michigan-dispensary-near-indiana",
    component: () => import("/tmp/build_2c622580/pages/michigan-dispensary-near-indiana.vue")
  },
  {
    name: "michigan-dispensary-near-ohio",
    path: "/michigan-dispensary-near-ohio",
    component: () => import("/tmp/build_2c622580/pages/michigan-dispensary-near-ohio.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build_2c622580/pages/newsletter.vue")
  },
  {
    name: "order-tracker",
    path: "/order-tracker",
    component: () => import("/tmp/build_2c622580/pages/order-tracker.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/tmp/build_2c622580/pages/privacy-policy.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/tmp/build_2c622580/pages/products/[slug].vue")
  },
  {
    name: "quick-order",
    path: "/quick-order",
    component: () => import("/tmp/build_2c622580/pages/quick-order.vue")
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/tmp/build_2c622580/pages/return-policy.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/tmp/build_2c622580/pages/reviews.vue")
  },
  {
    name: "staging-login",
    path: "/staging-login",
    meta: staging_45loginS0RmCDkbJTMeta || {},
    component: () => import("/tmp/build_2c622580/pages/staging-login.vue")
  },
  {
    name: "strain-library",
    path: "/strain-library",
    component: () => import("/tmp/build_2c622580/pages/strain-library.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/tmp/build_2c622580/pages/terms-of-use.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/tmp/build_2c622580/pages/thank-you.vue")
  },
  {
    name: "weedys-wear",
    path: "/weedys-wear",
    component: () => import("/tmp/build_2c622580/pages/weedys-wear.vue")
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/tmp/build_2c622580/pages/wholesale.vue")
  }
]
import { onINP } from 'web-vitals/attribution';
import type { INPMetricWithAttribution } from 'web-vitals/attribution';
import * as Sentry from '@sentry/vue';

interface Script {
	name: string;
	entryType: string;
	startTime: number;
	duration: number;
	invoker: string;
	invokerType: string;
	windowAttribution: string;
	executionStart: number;
	forcedStyleAndLayoutDuration: number;
	pauseDuration: number;
	sourceURL: string;
	sourceFunctionName: string;
	sourceCharPosition: number;
}

export default defineNuxtPlugin({
	name: 'web-vitals-plugin',
	parallel: true,
	async setup(nuxtApp) {
		const route = nuxtApp._route.path;
		if (['/api', '/dashboard'].includes(route) || import.meta.dev) {
			return;
		}

		function sendToAnalytics({ name, value, rating, attribution }: INPMetricWithAttribution) {
			try {
				const loaf = attribution.longAnimationFrameEntries.at(-1);
				// web.dev shows the usage of loaf.scripts here: https://web.dev/articles/find-slow-interactions-in-the-field
				// @ts-expect-error scripts exists but is missing from the web vitals type definitions
				const scripts = loaf?.scripts ?? [];
				const script = ([...scripts] as Script[]).sort(
					(a, b) => b.duration - a.duration,
				)[0];
				if (script) {
					const {
						invokerType,
						invoker,
						sourceURL,
						sourceCharPosition,
						sourceFunctionName,
					} = script;

					const extendedAttribution = {
						...attribution,
						invokerType,
						invoker,
						sourceURL,
						sourceCharPosition,
						sourceFunctionName,
					};
					attribution = extendedAttribution;
				}

				const body = JSON.stringify({ name, value, rating, attribution });
				if ('sendBeacon' in navigator) {
					navigator.sendBeacon('/api/_web-vitals', body);
				}
			} catch (error) {
				if (import.meta.dev) {
					console.error(error);
				} else {
					Sentry.withScope((scope) => {
						scope.setTag('metric_name', name);
						scope.setLevel('error');
						scope.setContext('metric_data', {
							name,
							value,
							rating,
							attribution,
						});
						Sentry.captureException(error);
					});
				}
			}
		}

		onINP(sendToAnalytics);
	},
});

/**
 * Auth middleware for staging env. Disallows access to entire app without logging in.
 */
export default defineNuxtRouteMiddleware(async (to) => {
	if (process.env.NUXT_ENV_IS_STAGING === 'true' && process.env.IS_LOCAL_STAGING !== 'true') {
		try {
			const { data } = await useFetch<Record<'valid', boolean>>(
				'/api/staging-auth/validate-token',
			);
			if (!data.value?.valid) {
				if (to.path !== '/staging-login') {
					return navigateTo('/staging-login');
				}
			}
			return;
		} catch (error) {
			console.error('Error validating token:', error);
			return navigateTo('/staging-login');
		}
	}
});
